


















































































































import PrintComponent from '@/components/printcomponent/PrintComponent';
export default PrintComponent;
